export function header() {

    const header = document.querySelector('.j-header');
    const header_btn = document.querySelector('.j-header_btn');
    const header_links = document.querySelectorAll('.j-header_link');

    header_btn.addEventListener('click', ()=>{
      if(header.classList.contains('active')){
        header.classList.remove('active');
      }else {
        header.classList.add('active');
      }
    });
    
    [...header_links].forEach((link) => {
      link.addEventListener('click', () => {
          header.classList.remove('active');
      });
    });

    let vh = window.innerHeight;
    window.addEventListener('scroll', () => {
      let y = window.pageYOffset;
      if(vh < y){
        header.classList.add('bg');
      }else {
        header.classList.remove('bg');
      }
    });
  }
  