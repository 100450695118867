export function follow() {

    if(document.querySelector('.j-follow') != null){
        const follow = document.querySelector('.j-follow');
        const follow_btn = document.querySelector('.j-follow_btn');
    
        follow_btn.addEventListener('click', ()=>{
          if(follow.classList.contains('active')){
            follow.classList.remove('active');
          }else {
            follow.classList.add('active');
          }
        });

    }    
  }
  